@import 'src/style/common';

.footer {
  margin-top: -2px;
  height: vwPC(193);
  background: #000000;
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  font-size: 2.083vw;
  line-height: 2.083vw;
  color: $white;
  display: flex;
  justify-content: space-between;

  @include phone-down() {
    font-weight: 400;
    font-size: rem(12);
    line-height: 12px;
    justify-content: center;
    flex-direction: column;
    height: vwMB(116);
  }

  .gov_number {
    display: block;
    margin-right: vwPC(104.24);
    margin-top: vwPC(55);
    width: vwPC(507.8);
    height: vwPC(84.1);

    @include phone-down() {
      display: none;
    }
  }

  .gov_number_mobile {
    display: none;

    @include phone-down() {
      display: block;
      width: vwMB(263);
      height: vwMB(22.5);
      margin-left: vwMB(49.23);
      margin-top: vwMB(17);
    }
  }

  .first_line {
    padding-top: vwPC(42);
    margin-left: vwPC(109);
    display: flex;
    align-items: center;

    @include phone-down() {
      justify-content: center;
    }

    .inst_icon {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0.694vw;

      .star_instagram {
        transition: all 0.5s ease-out;
        cursor: pointer;
        width: 3.82vw;
        height: 3.82vw;

        @include phone-down() {
          width: 19px;
          height: 19px;
        }

        &:hover {
          path {
            fill: $yellow;
          }
        }
      }

      .inst {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        width: 1.736vw;
        height: 1.736vw;

        @include phone-down() {
          width: 8.64px;
          height: 8.61px;
        }
      }

      .tik {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
        width: vwPC(21);
        height: vwPC(24.5);
      }
    }

  }

  .second_line {
    margin-top: vwPC(17);
    display: flex;
    align-items: center;
    margin-left: vwPC(109);

    @include phone-down() {
      margin-left: 0;
      justify-content: center;
    }

    a {
      color: #EB627E;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $yellow;
      }
    }
  }

  .mobile {
    display: none;

    @include phone-down() {
      width: 100%;
      height: 20px;
      display: block;
      background: #000000;
    }
  }
}