@import "src/style/common";

.faqItem {
  border-top: 3px solid #f3cc00;
  padding: 27px 45px 27px 0;
  cursor: pointer;

  @media (max-width: 1440px) {
    padding: 25px 45px 25px 0;
  }

  @media (max-width: 1180px) {
    padding: 15px 15px 15px 0;
  }

  @media (max-width: 640px) {
    padding: 10px 10px 10px 0;
    border-top: 0.5px solid #f3cc00;
  }
}

.faqItemTitle {
  color: #fff;
  font-family: "Yanone Kaffeesatz";
  font-style: normal;
  font-weight: 700;
  font-size: 3.03vw;
  line-height: 3.58vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemMrgn {
  margin-top: 35px;
}

.faqItem:last-child {
  border-bottom: 3px solid #f3cc00;

  @media (max-width: 640px) {
    border-bottom: 0.5px solid #f3cc00;
  }
}

.arrowStatic {
  rotate: 180deg;

  @media (max-width: 1080px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 440px) {
    width: 20px;
    height: 20px;
  }
}

.arrowClose {
  background-color: #f3cc00;
  border-radius: 50px;

  @media (max-width: 1080px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 440px) {
    width: 20px;
    height: 20px;
  }
}

.faqItemAsk {
  font-size: 2.03vw;
  line-height: 2.58vw;
  color: #fff;
  font-family: "Yanone Kaffeesatz";
  width: 80%;
  display: block;
  margin-top: 15px;
  font-weight: 400;

  @media (max-width: 440px) {
    margin-top: 5px;
  }
}
