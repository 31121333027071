$pink: #EB627E;
$white: #FFF;
$green: #93D500;
$yellow: #F3CC00;
$purple: #A63D87;

//
$baseFontSize: 16px;

// sizes
$breakpoint-desktop-xl: 1920px;
$breakpoint-desktop-large: 1440px;
$breakpoint-desktop-large-little: 1350px;
$breakpoint-desktop: 1200px;
$breakpoint-laptop: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 480px;
$breakpoint-phone-small: 375px;
$breakpoint-phone-xs: 320px;