@import 'src/style/common';

.wrapper {
  background: $purple;
  margin-top: vwPC(-316);
  height: vwPC(936);
  position: relative;
  overflow-x: clip;

  // @include phone-down() {
  //   margin-top: -2px;
  //   height: vwMB(140);
  // }

  .green_bottle {
    position: absolute;
    width: 16%;
    height: auto;
    right: 14%;
    bottom: vwPC(173.04);
    z-index: 10;
    display: block;

    // @include phone-down() {
    //   display: none;
    // }
  }

  .bottle_mobile {
    display: none;

    // @include phone-down() {
    //   display: block;
    //   width: vwMB(221.29);
    //   height: vwMB(247.28);
    //   position: absolute;
    //   right: vwMB(-17);
    //   bottom: vwMB(10);
    //   z-index: 3;
    // }
  }

  .energy_left{
    width: 12%;
    height: auto;
    position: absolute;
    bottom: 50%;
    left: vwPC(0);
    z-index: 2;
    display: block;
  }

  .crazy_kent_purple {
    width: 47%;
    height: auto;
    position: absolute;
    bottom: 4%;
    left: -4%;
    z-index: 2;
    display: block;

    // @include phone-down() {
    //   display: none;
    // }
  }

  .coffe {
    width: 16%;
    height: auto;
    position: absolute;
    bottom: vwPC(627.09);
    left: vwPC(831);
    z-index: 2;
    display: block;
  }

  .spray_left {
    position: absolute;
    top: -15.83vw;
    left: 6.25vw;
    width: 8.89vw;
    height: 7.92vw;
  }

  .spray_right {
    display: none;
  }

    .lime_energy {
      width: 24%;
      height: auto;
      position: absolute;
      bottom: 52.18px;
      right: -6%;
      z-index: 2;
      display: block;
    }


    // @include phone-down() {
    //   display: block;
    //   position: absolute;
    //   width: vwMB(17.5);
    //   height: vwMB(24.58);
    //   top: vwMB(-72);
    //   right: 0;
    // }
  }

  .union_eat_mobile {
    display: none;

    // @include phone-down() {
    //   display: block;
    //   width: vwMB(131);
    //   height: vwMB(88);
    //   position: absolute;
    //   right: vwMB(138);
    //   bottom: vwMB(78);
    //   opacity: 0;
    //   transition: all 0.5s ease-out;
    // }
  }

  .union_eat_mobile_show {

    // @include phone-down() {
    //   transition: all 0.5s ease-out;
    //   width: vwMB(188.66);
    //   height: vwMB(126.22);
    //   opacity: 1;
    // }
  }

  .melon_half {
    position: absolute;
  }

  .lazy_people {
    cursor: pointer;
    position: absolute;
    bottom: 2.29vw;
    right: 23.67vw;
    width: 31.39vw;
    height: 28.4vw;
  }

  .kiwi_banana_lime_text {
    position: absolute;
    bottom: 9%;
    left: vwPC(776);
    width: 19%;
    height: auto;
    z-index: 2;
    display: block;

    // @include phone-down() {
    //   display: none;
    // }
  }

  .yellow_mobile_text {
    display: none;

    // @include phone-down() {
    //   display: block;
    //   position: absolute;
    //   width: vwMB(173.59);
    //   height: vwMB(114.59);
    //   left: vwMB(-23);
    //   bottom: vwMB(10);
    // }
  }

  .melon_2 {
    position: absolute;
    left: 5.14vw;
    bottom: 13.74vw;
    width: 10.97vw;
    height: 7.43vw;
  }

  .melon_half {
    position: absolute;
    bottom: 17.49vw;
    left: 0;
    width: 10.9vw;
    height: 11.67vw;
  }

  .union_purple {
    position: absolute;
    left: 26%;
    bottom: 45%;
    width: 38%;
    height: auto;
    transition: all 0.5s ease-out;
    z-index: 2;
    opacity: 0;
    display: block;

    // @include phone-down() {
    //   display: none;
    //   opacity: 1;
    //   width: 51.6vw;
    //   height: 34.02vw;
    // }
  }

  .union_show {
    width: 36%;
    height: 35%;
    transition: all 0.5s ease-out;
    opacity: 1;
  }

  .cofe {
    width: 20.83vw;
    height: 20.83vw;
    position: absolute;
    top: -16.11vw;
    left: 52.18vw;
  }

  .lazy_animation {
/*    animation: up 3s ease-out;
    animation-fill-mode: forwards;*/
  }

  .union_animation {
/*    animation: upUnion 3s ease-out;
    animation-fill-mode: forwards;*/
  }

  @keyframes up {
    from {
      bottom: -5vw;
    }
    to {
      bottom: 2.29vw;
    }
  }
  @keyframes upUnion {
    from {
      bottom: 13.57vw;
    }
    to {
      bottom: 22.57vw;
    }
  }

